import schttp from 'public/src/services/schttp'

// 查询是否支持已发货修改 地址
export function queryBatchCheckUrge ({ check_urge_request_list } = {}) {
  if (check_urge_request_list?.length) {
    return schttp({
      method: 'POST',
      url: '/api/orders/query/queryBatchCheckUrge',
      data: {
        check_urge_request_list
      }
    })
  } else {
    return {}
  }
}
